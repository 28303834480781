import { autoinject } from 'aurelia-framework';

type SortableTableEntry = {
  SortIndex: number;
};

const sortSortIndex = (a: SortableTableEntry, b: SortableTableEntry) => a.SortIndex - b.SortIndex;

@autoinject
export class TableEntryManager {
  public addEntry<T extends SortableTableEntry[]>(list: T, entry: SortableTableEntry, index?: number): T {
    if (!Object.keys(entry).some((x) => x === 'SortIndex')) {
      console.error('Object must have sort index');
      throw new Error('Object must have sort index');
    }
    if (!list) list = [] as T;

    if (index !== undefined) {
      list.splice(index + 1, 0, entry);
    } else {
      list.push(entry);
    }

    return list.map((x, i) => {
      x.SortIndex = i;
      return x;
    }) as T;
  }

  public organizeSortIndex(list: SortableTableEntry[]) {
    return list.map((x, i) => {
      x.SortIndex = i;
      return x;
    });
  }

  public removeEntry<T extends SortableTableEntry[]>(list: T, index: number): T {
    list.splice(index, 1);
    return list
      .map((x, i) => {
        x.SortIndex = i;
        return x;
      })
      .sort(sortSortIndex) as T;
  }

  public moveEntryUp<T extends SortableTableEntry[]>(list: T, index: number): T {
    const nextIndex = index - 1;

    if (nextIndex < 0) return;

    const componentOnNextIndex = list[nextIndex];
    const componentOnCurrentIndex = list[index];

    componentOnCurrentIndex.SortIndex = nextIndex;
    componentOnNextIndex.SortIndex = index;

    return list.sort(sortSortIndex) as T;
  }

  public moveEntryDown<T extends SortableTableEntry[]>(list: T, index: number): T {
    const nextIndex = index + 1;
    if (nextIndex >= list.length) return;

    const componentOnNextIndex = list[nextIndex];
    const componentOnCurrentIndex = list[index];

    componentOnCurrentIndex.SortIndex = nextIndex;
    componentOnNextIndex.SortIndex = index;

    return list.sort(sortSortIndex) as T;
  }
}
